import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { ReactNode } from "react";

type ThumbnailProps = {
  title?: ReactNode;
  alt: string;
  imageData: IGatsbyImageData;
};

export const Thumbnail: React.FC<ThumbnailProps> = ({
  title,
  alt,
  imageData,
}) => {
  return (
    <div className="relative aspect-w-1 aspect-h-1 bg-black group overflow-hidden">
      <div>
        <GatsbyImage
          alt={alt}
          image={imageData}
          className="group-hover:scale-110 transition-transform duration-700"
        />
      </div>
      {title && (
        <div className="flex items-end text-white text-left">
          <h3 className="w-full px-4 pb-2 pt-8 bg-gradient-to-t from-black from-black">
            {title}
          </h3>
        </div>
      )}
    </div>
  );
};
